import app from "../app";

export class LazyContent {
  constructor() {
    // Lazy load content
    var $els = $('.lazy-content');

    if ( !$els.length ) return;

    $els.each((index, element) => {
      var $el = $(element);

      $el.hide().after($el.attr('data-lazy-content'));
      $el.closest('.responsive-embed').addClass('lazy-content-loaded');
    });
  }
}

export default (() => {
  app.pageLoad.push( () => {
    new LazyContent();
  });
})();
