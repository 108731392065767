import app from "../app";
import { gsap } from "gsap";
import Flickity from 'flickity-bg-lazyload';

export class AnimatedCursor {
  constructor() {
    this.cursorableElements = [];

    const originalCursor = document.getElementById('cursor');;
    let cursor = originalCursor;

    if ( !cursor ) return;

    let cursorEventsAssigned = false;
    const $cursor = $(cursor);
    const trail = document.getElementById('cursor-trail');
    const $trail = $(trail);
    const defaultCursorSize = 4;
    const defaultTrailSize = 50;
    let cursorSize = defaultCursorSize;
    let trailSizeX = defaultTrailSize;
    let trailSizeY = trailSizeX;
    const x = app.windowWidth / 2 + 'px';
    const y = app.windowHeight / 2 + 'px';
    let transitionInProgress = false;
    let pauseTrail = false;
    let preventBulge = false;
    const defaultTrailDuration = 0.3;
    const mouse = {
      x: 0,
      y: 0
    };

    const setTransitionInProgress = () => {
      transitionInProgress = true;
      app.$html.addClass('transition-in-progress');
    }

    const setTransitionFinished = () => {
      transitionInProgress = false;
      app.$html.removeClass('transition-in-progress');
    }

    const emptyCursor = () => {
      $trail.empty()
      app.$body.removeClass('cursor-handoff-active');
      trailSizeX = defaultTrailSize;
      trailSizeY = trailSizeX;
    }

    const resetCursor = (duration) => {
      const transitionWasInProgress = transitionInProgress;

      transitionInProgress = false;

      emptyCursor();

      if ( !duration ) duration = 0;

      if ( transitionWasInProgress ) {
        gsap.killTweensOf(trail);
        gsap.to(trail, {
          duration: 0,
          left: mouse.x,
          top: mouse.y,
          width: 0,
          height: 0,
          borderRadius: '50%'
        });
      }

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: duration,
        width: trailSizeX,
        height: trailSizeY,
        left: mouse.x - (trailSizeX / 2),
        top: mouse.y - (trailSizeY / 2),
      });

      $trail.removeClass('cursor-trail--image cursor-trail--video cursor-trail--blend-mode-normal has-cursor--external-link');
      $cursor.removeClass('cursor--external-link');
    }

    let hasDoneInitialMove = false;

    const moveCursor = (e) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;

      if ( !hasDoneInitialMove ) {
        cursor.style.opacity = 1;
        trail.style.opacity = 1;
      }

      hasDoneInitialMove = true;

      if ( transitionInProgress ) return;

      gsap.to(cursor, {
        duration: 0,
        left: mouse.x - (cursorSize / 2),
        top: mouse.y - (cursorSize / 2),
        ease: 'elastic(0.8, 0.75)'
      });

      if ( !pauseTrail ) {
        gsap.to(trail, {
          duration: 1,
          left: mouse.x - (trailSizeX / 2),
          top: mouse.y - (trailSizeY / 2),
          ease: 'elastic(0.8, 0.75)'
        });
      }

      if ( e.target.tagName == 'IFRAME' ) {
        document.body.classList.add('cursor-is-hidden');
      } else {
        document.body.classList.remove('cursor-is-hidden');
      }
    }

    const bulgeCursor = () => {
      if ( preventBulge ) return;

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: 0.15,
        width: (trailSizeX / 0.7),
        height: (trailSizeY / 0.7),
        left: mouse.x - ((trailSizeX / 0.7) / 2),
        top: mouse.y - ((trailSizeY / 0.7) / 2),
        onComplete: () => {
          gsap.to(trail, {
            duration: 0.15,
            width: trailSizeX,
            height: trailSizeY,
            left: mouse.x - (trailSizeX / 2),
            top: mouse.y - (trailSizeY / 2)
          });
        }
      });
    }

    // When pausing Turbo, you can run into buggy behavior if a user pressed the back button before
    // the async animateOut function completes, which results in the newly navigated-to page (via the back button)
    // getting rendered with the async page's `event.detail.resume()` Turbo body. In this checkForHistory function,
    // we try to fix that edge case.
    let historyHasChangedWhileAnimating = false;
    const checkForHistoryChangeWhilePausingTurboRender = (e) => {
      historyHasChangedWhileAnimating = true;
    }

    const linkHandoffClick = (e) => {
      e.preventDefault();

      // TODO: there's an edge case bug when you click twice quickly on a link, it messes up the transition.

      const cursorable = e.target.closest('[data-cursorable]');
      const handoffPath = cursorable.dataset.handoffPath;

      setTransitionInProgress();

      const animateOut = async () => {
        return new Promise((resolve) => {
          gsap.killTweensOf(trail);
          gsap.to(trail, {
            duration: 0.6,
            width: app.windowWidth * 2,
            height: app.windowHeight * 2,
            top: (app.windowHeight * 2) / -4,
            left: (app.windowWidth * 2) / -4,
            // borderRadius: 0,
            onComplete: () => {
              resolve();
            }
          });
        });
      }

      removeCursorableEventListeners();

      // First clear the turbo, otherwise it looks like the video jumps when turbo shows the cached preview
      Turbo.clearCache();
      Turbo.visit(handoffPath);

      const animateOutPromise = animateOut();

      // https://turbo.hotwired.dev/handbook/drive#pausing-rendering
      document.addEventListener('turbo:before-render', async (e) => {
        e.preventDefault();

        window.addEventListener('popstate', checkForHistoryChangeWhilePausingTurboRender, { once: true });

        await animateOutPromise;

        // In order to prevent videos from looking like they restart when you land on the project#show page,
        // we pull the project header out of the cursor and append it into the project#show page.
        const newProjectHeader = e.detail.newBody.querySelector('.project__header');
        const $oldProjectHeader = $(trail.querySelector('.project__header'));
        newProjectHeader.remove();

        e.detail.newBody.classList.add('has-performed-page-animation');

        if ( historyHasChangedWhileAnimating ) {
          location.reload();
        } else {
          e.detail.resume();

          document.addEventListener('turbo:load', (e) => {
            $oldProjectHeader.appendTo('#project-show__header-wrapper');
            resetCursor(defaultTrailDuration);

            $('.scroll-reveal').each((index, el) => {
              const $el = $(el);
              const offset = $el.offset().top;

              if ( offset <= app.windowHeight + app.scrollTop ) {
                $el.removeClass('scroll-reveal');
              }
            });
          }, { once: true });
          historyHasChangedWhileAnimating = false;
        }

        window.removeEventListener('popstate', checkForHistoryChangeWhilePausingTurboRender, { once: true });
      }, { once: true })
    }

    const cursorHandoff = (element) => {
      const handoff = element.querySelector('[data-cursorable-handoff-element]');
      const cursorable = element.closest('[data-cursorable]');

      if ( !handoff ) return;

      $trail.html(handoff.innerHTML);

      app.$body.addClass('cursor-handoff-active');

      $(cursorable).one('click', linkHandoffClick);
    }

    const disableCursorHandoff = (element) => {
      const cursorable = element.closest('[data-cursorable]');
      resetCursor(defaultTrailDuration);
      $(cursorable).off('click', linkHandoffClick);
    }

    const cursorHighlight = (element) => {
      pauseTrail = true;

      const cursorable = element.closest('[data-cursorable]');
      const bb = cursorable.getBoundingClientRect();

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: 0.3,
        width: bb.width,
        height: bb.height,
        left: bb.left,
        top: bb.top,
        borderRadius: 0
      });
    }

    const cursorImage = (element) => {
      const url = element.getAttribute('data-cursorable-media-url');
      const $media = $('<img src="' + url + '" class="cursor-trail__image">');
      const mediaRatio = parseFloat(element.getAttribute('data-media-ratio'));
      const cursorableSize = element.getAttribute('data-cursorable-size');

      if ( cursorableSize == 'small' ) {
        trailSizeX = Math.min((app.windowWidth / 4), 300);
      } else {
        trailSizeX = Math.max((app.windowWidth / 3), 300);

        // Vertical images
        if ( mediaRatio > 1 ) {
          trailSizeX = Math.min((app.windowWidth / 4), 300);
        }
      }

      trailSizeY = trailSizeX * mediaRatio;
      preventBulge = true;

      $trail.addClass('cursor-trail--image').html($media);
    }

    const cursorVideo = (element) => {
      const $element = $(element);
      const url = element.getAttribute('data-cursorable-media-url');
      const posterImageUrl = element.getAttribute('data-cursorable-poster-image-url');
      const posterImageAttr = posterImageUrl ? 'poster="' + posterImageUrl + '"' : '';
      const $media = $('<video src="' + url + '" ' + posterImageAttr + ' class="cursor-trail__video" muted playsinline autoplay loop>');
      const mediaRatio = parseFloat(element.getAttribute('data-media-ratio'));
      const currentTime = parseFloat($element.data('current-video-time') || 0);

      const cursorableSize = element.getAttribute('data-cursorable-size');

      if ( cursorableSize == 'small' ) {
        trailSizeX = Math.min((app.windowWidth / 4), 300);
      } else {
        trailSizeX = Math.max((app.windowWidth / 2.5), 300);

        // Vertical images
        if ( mediaRatio > 1 ) {
          trailSizeX = Math.max((app.windowWidth / 3.5), 300);
        }
      }

      trailSizeY = trailSizeX * mediaRatio;
      preventBulge = true;

      $trail.addClass('cursor-trail--video').html($media);

      const video = $media[0];

      video.addEventListener('loadedmetadata', () => {
        video.currentTime = currentTime;
      }, { once: true });

      let playPromise = $media[0].play();

      if ( playPromise !== undefined ) {
        playPromise.then(function() {
          // Automatic playback started
        }).catch(function(error) {
          // Auto-play was prevented
          console.warn('Error playing video', error);
        });
      }
    }

    const cursorExternalLink = (element) => {
      $cursor.addClass('cursor--external-link');
      $trail.addClass('has-cursor--external-link');
    }

    const cursorSlideshow = (element) => {
      const $carousel = $(element);
      const $mm = $carousel.closest('.magic-module');
      const moduleId = $mm.attr('id');
      const flkty = Flickity.data($carousel[0]);
      const totalSlides = flkty.slides.length;

      if ( totalSlides < 2 ) return;

      const currentIndex = flkty.selectedIndex + 1;
      const labelDiv = $('<div class="cursor__slideshow-label">' + currentIndex + ' of ' + totalSlides + '</div>');
      $trail.addClass('cursor-trail--slideshow').data('moduleId', moduleId).html(labelDiv);
    }

    const onCursorableHover = (e) => {
      const element = e.target;
      const cursorable = element.closest('[data-cursorable="handoff"]') || element;
      const cursorableType = cursorable.dataset.cursorable;
      const label = cursorable.dataset.cursorLabel;
      const cursorableBlendMode = element.getAttribute('data-cursorable-blend-mode');

      if ( cursorableBlendMode == 'normal' ) {
        $trail.addClass('cursor-trail--blend-mode-normal');
      }

      cursorSize = 0;
      trailSizeX = 100;
      trailSizeY = trailSizeX;

      if ( cursorableType == 'handoff' ) {
        trailSizeX = 300;
        trailSizeY = trailSizeX;
        cursorHandoff(element);
      } else if ( cursorableType == 'highlight' ) {
        cursorHighlight(element);
        return;
      } else if ( cursorableType == 'image' ) {
        cursorImage(element);
      } else if ( cursorableType == 'video' ) {
        cursorVideo(element);
      } else if ( cursorableType == 'external-link' ) {
        cursorExternalLink(element);
      } else if ( cursorableType == 'slideshow' ) {
        cursorSlideshow(element);
      } else {
        if ( element.closest('[data-cursorable="handoff"]') ) return;

        if ( cursorableType == 'enlarge' ) {
          trailSizeX = 300;
          trailSizeY = trailSizeX;
        }
      }

      if ( label ) {
        const labelDiv = document.createElement('div');
        const labelText = document.createTextNode(label);
        labelDiv.appendChild(labelText);
        labelDiv.classList.add('cursor__label');
        trail.append(labelDiv);
      }

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: 0.3,
        width: trailSizeX,
        height: trailSizeY,
        left: mouse.x - (trailSizeX / 2),
        top: mouse.y - (trailSizeY / 2)
      });
    }

    const offCursorableHover = (e) => {
      // return;

      const element = e.target;
      const $element = $(element);
      const cursorableType = element.dataset.cursorable;

      preventBulge = false;

      $trail.removeClass('cursor-trail--blend-mode-normal');

      if ( cursorableType == 'handoff' ) {
        disableCursorHandoff(element);
        return;
      } else if ( cursorableType == 'image' ) {
        $trail.removeClass('cursor-trail--image').empty();
      } else if ( cursorableType == 'video' ) {
        let video = $trail.find('video')[0];

        if ( video ) {
          $element.data('current-video-time', video.currentTime);
          video.pause();
          video.src = '';
          video.remove();
          video = null;
        }

        $trail.removeClass('cursor-trail--video').empty();
      } else if ( cursorableType == 'external-link' ) {
        $cursor.removeClass('cursor--external-link').empty();
        $trail.removeClass('has-cursor--external-link');
      } else if ( cursorableType == 'slideshow' ) {
        $trail.removeClass('cursor-trail--slideshow').data('moduleId', undefined).empty();
      } else if ( element.closest('[data-cursorable="handoff"]') ) {
        return;
      }

      trailSizeX = defaultTrailSize;
      trailSizeY = trailSizeX;

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: 0.3,
        width: trailSizeX,
        height: trailSizeY,
        left: mouse.x - (trailSizeX / 2),
        top: mouse.y - (trailSizeY / 2)
      });
    }

    const initialize = () => {
      this.setCursorableElements();

      for (var i = this.cursorableElements.length - 1; i >= 0; i--) {
        this.cursorableElements[i].addEventListener('mouseenter', onCursorableHover);
        this.cursorableElements[i].addEventListener('mouseleave', offCursorableHover);
      }

      app.$html.removeClass('transition-in-progress');

      resetCursor();

      if ( app.isHomePage() && !app.$body.hasClass('home-page-intro-animation') ) {
        app.$body.addClass('show-home-logo-reveal home-logo-reveal--no-transition');
      }
    }

    const removeCursorableEventListeners = () => {
      for (var i = this.cursorableElements.length - 1; i >= 0; i--) {
        this.cursorableElements[i].removeEventListener('mouseenter', onCursorableHover);
        this.cursorableElements[i].removeEventListener('mouseleave', offCursorableHover);
      }

      this.cursorableElements = [];
    }

    const setMouseMoveListeners = () => {
      window.addEventListener('mousemove', moveCursor, { passive: true });
      window.addEventListener('mousedown', bulgeCursor);
      cursorEventsAssigned = true;
    }

    const cursorShrinkAnimation = () => {
      gsap.to(trail, {
        duration: 0.5,
        delay: 0.4,
        width: defaultTrailSize,
        height: defaultTrailSize,
        left: mouse.x - (defaultTrailSize / 2),
        top: mouse.y - (defaultTrailSize / 2),
        ease: 'expo.out',
        onStart: () => {
          trailSizeX = defaultTrailSize;
          trailSizeY = trailSizeX;
          app.$body.removeClass('home-page-intro-animation--begin').addClass('show-home-logo-reveal');
        },
        onComplete: () => {
          app.$body.addClass('home-page-intro-animation--complete');
          setMouseMoveListeners();
          initialize();
          app.reflow();
        }
      });

      window.removeEventListener('mousemove', cursorShrinkAnimation, { once: true });
      window.removeEventListener('keyup', cursorShrinkAnimation, { once: true });
      window.removeEventListener('scroll', cursorShrinkAnimation);
    }

    const homePageAnimation = () => {
      app.$body.addClass('home-page-intro-animation home-page-intro-animation--begin');

      mouse.x = app.windowWidth / 2;
      mouse.y = app.windowHeight / 2;
      trailSizeX = app.windowWidth - 30;
      trailSizeY = trailSizeX;

      gsap.killTweensOf(trail);
      gsap.to(trail, {
        duration: 0,
        opacity: 1,
        width: trailSizeX,
        height: trailSizeY,
        left: 15,
        top: app.windowHeight / 4
      });

      window.addEventListener('mousemove', cursorShrinkAnimation, { once: true });
      window.addEventListener('keyup', cursorShrinkAnimation, { once: true });

      // Use a setTimeout to assign the scroll listener so the initial call to scroll top doesn't trigger it
      window.setTimeout(() => {
        window.addEventListener('scroll', cursorShrinkAnimation);
      }, 10);

      app.teardown.push(() => {
        app.$body.removeClass('home-page-intro-animation home-page-intro-animation--begin');
      });
    }

    app.pageLoad.push(() => {
      initialize();
      if ( !cursorEventsAssigned ) setMouseMoveListeners();
    });

    if ( app.isHomePage() && window.location.hash != '#content' && app.scrollTop < 200 ) {
      homePageAnimation();
    } else {
      setMouseMoveListeners();
      initialize();
    }

    app.teardown.push(() => {
      if ( !transitionInProgress ) {
        emptyCursor();
        resetCursor(defaultTrailDuration);
      }
      removeCursorableEventListeners();
    });
  }

  setCursorableElements() {
    this.cursorableElements = [
      ...document.links,
      ...document.querySelectorAll('[data-cursorable]')
    ];
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    if ( app.breakpointIsMobile() ) {
      app.$document.on('click', '[data-handoff-path]', (e) => {
        const $target = $(e.currentTarget);
        const handoffPath = $target.attr('data-handoff-path');

        Turbo.visit(handoffPath);
      });

      return;
    }

    app.$document.on('mouseenter', 'iframe', () => {
      app.$body.addClass('window-not-focused');
    });

    app.$document.on('mouseleave', 'iframe', () => {
      app.$body.removeClass('window-not-focused');
    });

    app.$document.on('mouseleave', () => {
      app.$body.addClass('window-not-focused');
    });

    app.$document.on('mouseenter', () => {
      app.$body.removeClass('window-not-focused');
    });

    new AnimatedCursor();
  });
})();
