import app from "../app";

export class SmoothScroll {
  constructor() {
    if ( app.isEditing() ) return;

    if ( location.hash.length > 1 ) {
      let hash = location.hash.substr(1);
      let $el = $('[name="' + hash + '"], #' + hash).first();

      if ( $el.attr('data-default-jump-link') != undefined ) return;

      // carousel modules can mess up spacing of page, so wait a second before scrolling
      setTimeout(function() {
        if ( $el.length ) app.scrollTo($el);
      }, 250);
    }
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  app.pageLoad.push(() => {
    new SmoothScroll();
  });

  window.addEventListener('DOMContentLoaded', () => {
    if ( app.isEditing() ) return;

    app.$document.on('click', 'a[href*="#"]:not([href="#"])', (e) => {
      // If you want to disable the call to `e.preventDefault()` you can add a ` data-default-jump-link` attribute to the link.
      // This is good if e.g. you want to enable the default history behavior for jump links.
      // <a href="#jump-link" data-default-jump-link>the hash in this jump link get's added to the current URL as usual</a>

      let currentTarget = e.currentTarget;
      let $target = $(e.target);

      if ( location.pathname.replace(/^\//,'') == currentTarget.pathname.replace(/^\//,'' ) && location.hostname == currentTarget.hostname && location.search == currentTarget.search) {
        let target = $(currentTarget.hash);
        target = target.length ? target : $('[name=' + currentTarget.hash.slice(1) +']');

        if ( target.length ) {
          let offset = parseInt( $target.attr('data-smooth-scroll-offset') );
          app.scrollTo(target, offset);

          if ( $(currentTarget).attr('data-default-jump-link') == undefined ) {
            e.preventDefault();
          }
        }
      }
    });
  });
})();
