import app from "../app";

export class JsLinks {
  constructor() {
    App.$document.on('click', '[data-js-link]', function(e) {
      var $target = $(e.target);

      if ( $target.is('a') ) return;

      var $jsLink = $(this);
      var url = $jsLink.attr('data-js-link');
      var isExternalLink = false;

      if ( $jsLink.attr('data-cursorable') == 'external-link' ) isExternalLink = true;
      if ( $jsLink.hasClass('external-link') ) isExternalLink = true;

      if ( url ) {
        if ( e.metaKey || e.ctrlKey || isExternalLink ) {
          window.open( url, '_blank' );
        } else if ( e.shiftKey ) {
          window.open( url, '_blank' );
          window.focus();
        } else {
          Turbo.visit(url);
        }
      }
    });
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    new JsLinks();
  });
})();
