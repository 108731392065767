import app from "../app";

export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    var hasAssignedEventListener = false;

    App.$document.on('change', '.newsletter-signup-form__input', function() {
      var $input = $(this);

      if ( $input.val() ) {
        app.$window.off('scroll.newsletterSignupForm');
        app.$window.on('scroll.newsletterSignupForm', function() {
          if ( app.scrollTop + app.windowHeight <= ( app.$document.height() - $('#footer').outerHeight(true) ) ) {
            app.$body.removeClass('is-viewing-footer');
          }
        });
      } else {
        app.$window.off('scroll.newsletterSignupForm');
      }
    });

    app.teardown.push(function() {
      $('.newsletter-signup-form__input').val('');
      app.$window.off('scroll.newsletterSignupForm');
    });

    App.$document.on('submit', '.newsletter-signup-form', function() {
      var $form = $(this);
      var $successMessage = $form.find('.newsletter-signup-form__success-message');
      var $errorMessage = $form.find('.newsletter-signup-form__error-message');
      var $inputs = $form.find('input');
      var $input = $form.find('.newsletter-signup-form__input');
      var $submit = $form.find('.newsletter-signup-form__submit');

      // Filter out duplicate inputs that exist when showing the mobile layout of the newsletter signup
      var $duplicateInputs = $inputs.filter(':hidden').filter(function() {
        return $(this).attr('type') != 'hidden';
      });

      $duplicateInputs.prop('disabled', true);

      $.ajax({
        type: 'GET',
        url: $form.attr('action'),
        data: $form.serialize(),
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        error: function(data) {
          $errorMessage.show().html('Newsletter signup error: could not connect to the registration server.');
        },
        success: function(data) {
          if ( data.result != 'success' ) {
            $errorMessage.show().html( data.msg.replace(/^\d* - /, '') );
          } else {
            $errorMessage.remove();
            $input.remove();
            $submit.remove();
            $successMessage.show();
          }
        }
      });

      return false;
    });
  });
})();
