import app from "../app";
import { gsap } from "gsap";
import throttle from 'lodash/throttle'

export class FooterNextProjectLinks {
  constructor() {
    const nextProject = document.getElementById('footer__next-project');

    if ( !nextProject ) return;

    const link = document.getElementById('footer__next-project__link');
    const $link = $(link);
    const contactButton = document.getElementById('footer__contact-button');
    const footer = document.getElementById('footer');
    const $adminLinks = $('#admin-links');
    const $footer = $(footer);
    const projectHeader = nextProject.querySelector('.project__header');
    const $projectHeader = $(projectHeader);
    const label = document.getElementById('footer__next-project__next-label');
    const $label = $(label);

    if ( link.classList.contains('turbo-needs-restore') ) {
      $link.css({
        width: '',
        height: '',
        top: '',
        left: '',
        position: '',
        borderRadius: ''
      });

      $projectHeader.css({
        left: '',
        opacity: ''
      });

      $label.css({
        opacity: ''
      });
    }

    const animateOut = async () => {
      return new Promise((resolve) => {
        const top = $link.offset().top - app.scrollTop;

        link.classList.add('turbo-needs-restore');

        gsap.to(link, {
          duration: 0.4,
          borderRadius: 0
        });

        $link.css({
          width: link.offsetWidth,
          height: link.offsetHeight,
          top: top,
          left: 0,
          position: 'fixed'
        });

        $projectHeader.css({
          left: 0
        });

        gsap.to(projectHeader, {
          duration: 0.4,
          opacity: 1
        });

        gsap.to(label, {
          duration: 0.4,
          opacity: 0
        });

        gsap.to(link, {
          duration: 0.8,
          top: app.$header.outerHeight(true),
          width: link.offsetWidth,
          height: link.offsetHeight,
          ease: "power4.out",
          onComplete: () => {
            resolve();
          }
        });
      });
    };

    const mouseenterHandler = () => {
      gsap.killTweensOf(link);
      gsap.to(link, {
        duration: 0.4,
        borderRadius: 0
      });

      gsap.to(projectHeader, {
        duration: 0.4,
        opacity: 1
      });
    }

    const newsletterIsBeingFilledOut = () => {
      const $newsletterInput = $footer.find('.newsletter-signup-form__input');

      if ( $newsletterInput.length && $newsletterInput.val() ) {
        return true;
      } else {
        return false;
      }
    }

    const mouseleaveHandler = (e) => {
      if ( e.relatedTarget && e.relatedTarget.getAttribute('id') == 'footer__contact-button' ) return;

      gsap.killTweensOf(link);
      gsap.to(link, {
        duration: 0.4,
        borderRadius: '50%'
      });

      gsap.to(projectHeader, {
        duration: 0.4,
        opacity: 0
      });
    }

    let historyHasChangedWhileAnimating = false;
    const checkForHistoryChangeWhilePausingTurboRender = (e) => {
      historyHasChangedWhileAnimating = true;
    }

    const clickHandler = (e) => {
      if ( app.breakpointIsMobile() ) return;

      e.preventDefault();

      app.$body.addClass('is-transitioning-footer-project');

      link.removeEventListener('mouseleave', mouseleaveHandler);

      $projectHeader.find('video').addClass('turbo-persist-video');

      Turbo.clearCache();
      Turbo.visit(link.getAttribute('href'));

      const animateOutPromise = animateOut();

      // https://turbo.hotwired.dev/handbook/drive#pausing-rendering
      document.addEventListener('turbo:before-render', async (e) => {
        e.preventDefault();

        window.addEventListener('popstate', checkForHistoryChangeWhilePausingTurboRender, { once: true });

        await animateOutPromise;

        const newProjectHeader = e.detail.newBody.querySelector('.project__header');
        newProjectHeader.remove();

        e.detail.newBody.classList.add('has-performed-page-animation');

        if ( historyHasChangedWhileAnimating ) {
          location.reload();
        } else {
          e.detail.resume()

          document.addEventListener('turbo:load', (e) => {
            $projectHeader.appendTo('#project-show__header-wrapper');
            app.$body.removeClass('is-transitioning-footer-project');
          }, { once: true });
          historyHasChangedWhileAnimating = false;
        }

        window.removeEventListener('popstate', checkForHistoryChangeWhilePausingTurboRender, { once: true });
      }, { once: true })
    }

    const contactButtonMouseenterHandler = (e) => {
      let adminLinkHeight = 0;

      if ( $adminLinks.length ) {
        adminLinkHeight = $adminLinks.outerHeight(true);
      }

      $footer.css({ bottom: adminLinkHeight });

      app.$body.addClass('is-viewing-footer');

      app.$window.off('scroll.footerContactButton').on('scroll.footerContactButton', () => {
        if ( app.$document.height() - (app.scrollTop + app.windowHeight) > 70 ) {
          app.$window.off('scroll.footerContactButton');
          app.$body.removeClass('is-viewing-footer');
        }
      });
    }

    const contactButtonMouseleaveHandler = (e) => {
      const $relatedTarget = $(e.relatedTarget);

      if ( newsletterIsBeingFilledOut() ) return;
      if ( $relatedTarget.closest('#footer').length ) return;

      app.$body.removeClass('is-viewing-footer');
    }

    const footerMouseleaveHandler = (e) => {
      if ( newsletterIsBeingFilledOut() ) return;

      app.$body.removeClass('is-viewing-footer');
    }

    const checkForVisibility = (e) => {
      if ( !app.breakpointIsMobile() ) return;

      if ( app.inViewport($link) ) {
        mouseenterHandler(e);
      } else {
        mouseleaveHandler(e);
      }
    }

    link.addEventListener('mouseenter', mouseenterHandler);
    link.addEventListener('mouseleave', mouseleaveHandler);
    link.addEventListener('click', clickHandler);

    // Contact button hover-to-reveal interaction
    contactButton.addEventListener('mouseenter', contactButtonMouseenterHandler);

    app.$window.on('scroll.footerNextProjectLinks', throttle(checkForVisibility, 500));

    app.teardown.push(() => {
      link.removeEventListener('mouseenter', mouseenterHandler);
      link.removeEventListener('mouseleave', mouseleaveHandler);
      link.removeEventListener('click', clickHandler);
      contactButton.removeEventListener('mouseenter', contactButtonMouseenterHandler);
      app.$window.off('scroll.footerContactButton scroll.footerNextProjectLinks');
      app.$body.removeClass('is-viewing-footer is-transitioning-footer-project');
    });
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  app.pageLoad.push(() => {
    new FooterNextProjectLinks();
  });
})();
