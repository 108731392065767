/* JavaScript for ImageGridModule */

import app from '../app';
import debounce from 'lodash/debounce'

// Export the class itself
export class ImageGridModule {
  constructor() {
    this.$modules = $('.image-grid-module');

    if ( !this.$modules.length ) return;

    this.initialize();

    app.$window.on('resize.imageGridModule', debounce(this.reset.bind(this), 500));

    app.teardown.push(() => {
      this.destroy();
      app.$window.off('resize.imageGridModule');
    });
  }

  reset() {
    this.destroy();
    this.initialize();
  }

  initialize() {
    if ( app.breakpointIsMobile() ) return;

    this.$modules.each((index, module) => {
      const $module = $(module);
      const $offsetItems = $module.find('.image-grid-module-item__push-top');

      $offsetItems.each((index, offsetItem) => {
        const $offsetItem = $(offsetItem);
        const height = $offsetItem.height();

        $offsetItem.css({ marginTop: height / 2 });
      });
    });
  }

  destroy() {
    this.$modules.each((index, module) => {
      const $module = $(module);
      const $offsetItems = $module.find('.image-grid-module-item__push-top');

      $offsetItems.css({ marginTop: '' });
    });
  }
}

export default (() => {
  app.pageLoad.push( () => {
    new ImageGridModule();
  });
})();
