import app from "../app";

export default (() => {
  app.pageLoad.push(function() {
    const $button = $('.header__mobile-logo--circle');

    $button.on('click.mobileNavToggle', function() {
      if ( app.$body.hasClass('is-viewing-mobile-nav') ) {
        app.$body.removeClass('is-viewing-mobile-nav');
      } else {
        app.$body.addClass('is-viewing-mobile-nav');
      }
    });

    app.teardown.push(() => {
      $button.off('click.mobileNavToggle');
      app.$body.removeClass('is-viewing-mobile-nav');
    });
  });
})();
