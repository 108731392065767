/* JavaScript for InteractiveServiceListModule */

import app from '../app';
import intersection from 'lodash/intersection';

// Export the class itself
export class InteractiveServiceListModule {
  constructor() {
    this.$modules = $('.interactive-service-list-module');

    if ( !this.$modules.length ) return;

    this.initialize();
  }

  initialize() {
    this.$modules.each((index, module) => {
      const $module = $(module);
      const $items = $module.find('[data-service-id]');

      $items.on('mouseenter.interactiveServiceListModule', (e) => {
        const $item = $(e.currentTarget);
        const ids = $item.attr('data-service-id').split(',');
        const $activeItems = $items.filter((i, item) => {
          const idsForMatch = item.dataset.serviceId.split(',');
          return intersection(idsForMatch, ids).length;
        });

        $module.addClass('has-hover-interaction');
        $activeItems.addClass('active');
      });

      $items.on('mouseleave.interactiveServiceListModule', (e) => {
        const $item = $(e.currentTarget);

        $module.removeClass('has-hover-interaction');
        $items.removeClass('active');
      });

      app.teardown.push(() => {
        $items.off('mouseenter.interactiveServiceListModule mouseleave.interactiveServiceListModule');
      });
    });
  }
}

export default (() => {
  app.pageLoad.push( () => {
    new InteractiveServiceListModule();
  });
})();
