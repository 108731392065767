import app from "../app";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// https://greensock.com/docs/v3/Plugins/ScrollTrigger

export class ScrollTriggers {
  constructor() {
    if ( !document.querySelector('.scroll-reveal') ) return;

    let triggers = [];

    const initTriggers = () => {
      triggers = ScrollTrigger.batch('.scroll-reveal', {
        // interval: 1, // time window (in seconds) for batching to occur.
        batchMax: 10,   // maximum batch size (targets). Can be function-based for dynamic values
        onEnter: batch => (
          batch.forEach((el) => {
            el.classList.add('revealed');
          })
        ),
        start: 'top bottom'
      });
    }

    if ( app.$body.hasClass('has-performed-page-animation') ) {
      window.setTimeout(() => {
        initTriggers();
      }, 250);
    } else {
      initTriggers();
    }

    app.$document.on('app:reflow', () => {
      ScrollTrigger.refresh({ safe: true });
    });

    let timer = window.setTimeout(() => {
      ScrollTrigger.refresh({ safe: true });
    }, 200);

    document.addEventListener('turbo:before-cache', () => {
      if ( timer ) window.clearTimeout(timer);

      triggers.forEach((trigger) => {
        trigger.kill();
      });
    }, { once: true });
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  app.pageLoad.push(() => {
    new ScrollTriggers();
  });
})();
