/* JavaScript for SlideshowModule */

import app from '../app';
import throttle from 'lodash/throttle'
import * as viewport from "../utilities/viewport";
import Flickity from 'flickity';

// Export the class itself
export class SlideshowModule {
  constructor() {
    this.$carousels = $('[data-carousel]');

    if ( !this.$carousels.length ) return;

    this.$trail = $('#cursor-trail');

    this.initialize();
  }

  initialize() {
    const nextSlide = (event, pointer, cellElement, cellIndex) => {
      let $targetEl;

      if ( cellElement ) {
        $targetEl = $(cellElement);
      } else {
        $targetEl = $(event.target);
      }

      const $carousel = $targetEl.closest('[data-carousel]');
      const flkty = Flickity.data($carousel[0]);

      flkty.next();
    };

    const checkForVisibility = () => {
      this.$carousels.each((index, carousel) => {
        const $carousel = $(carousel);
        const flkty = Flickity.data($carousel[0]);

        if ( viewport.isVisible($carousel) ) {
          flkty.playPlayer();
        } else {
          flkty.pausePlayer();
        }
      });
    }

    this.$carousels.each((index, carousel) => {
      const $carousel = $(carousel);
      const $mm = $carousel.closest('.magic-module');
      const $wrapper = $carousel.closest('[data-carousel-wrapper]');
      const $caption = $wrapper.find('[data-carousel-caption]');
      const autoPlay = $carousel.attr('data-autoplay') ? (parseInt($carousel.attr('data-autoplay')) || false) : false;
      const flkty = new Flickity(carousel, {
        lazyLoad: 2,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        imagesLoaded: true,
        adaptiveHeight: false,
        autoPlay: autoPlay,
        pauseAutoPlayOnHover: false
      });

      flkty.on('staticClick', nextSlide);
      flkty.on('change', () => {
        const currentIndex = flkty.selectedIndex + 1;
        const totalSlides = flkty.slides.length;
        const labelDiv = $('<div class="cursor__slideshow-label">' + currentIndex + ' of ' + totalSlides + '</div>');
        const caption = flkty.selectedElement.getAttribute('data-caption') || '&nbsp;';

        if ( this.$trail.hasClass('cursor-trail--slideshow') && $mm.length && $mm.attr('id') == this.$trail.data('moduleId') ) {
          this.$trail.html(labelDiv);
        }
        $caption.html(caption);
      });

      document.addEventListener('turbo:before-cache', () => {
        flkty.off('staticClick', nextSlide);
        flkty.destroy();
      }, { once: true });
    });

    app.$window.on('scroll.slideshowModuleVisibility', throttle(checkForVisibility, 500));
    checkForVisibility();

    document.addEventListener('turbo:before-cache', () => {
      app.$window.off('scroll.slideshowModuleVisibility');
    }, { once: true });
  }
}

export default (() => {
  app.pageLoad.push( () => {
    new SlideshowModule();
  });
})();
