import app from "../app";

export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    app.$document.on('mouseenter', '.project-overview-module__item', (e) => {
      const $target = $(e.target);
      const $item = $target.closest('.project-overview-module__item');

      $item.addClass('active');
    });

    app.$document.on('mouseleave', '.project-overview-module__item', (e) => {
      const $target = $(e.target);
      const $item = $target.closest('.project-overview-module__item');

      $item.removeClass('active');
    });

    app.teardown.push(() => {
      $('.project-overview-module__item').removeClass('active');
    });
  });
})();
