import app from "../app";
import throttle from 'lodash/throttle'

// This javascripts solves a problem where you may have a <table> (or two separate tables)
// on a page that have their column size set automatically by the tables's default layout logic.
// But, you also have a separate table, or table-like set of divs, that should match the column
// size of the table.

export default (() => {
  app.pageLoad.push(function() {
    const $scopes = $('[data-column-header-size-match-scope]');

    if ( !$scopes.length ) return;

    var $allCols = $('[data-column-header]');
    var $allWidthTargetRows = $('[data-column-width-target-row]');
    var $allWidthTargets = $('[data-column-width-target]');

    var unsizeTargetRows = function() {
      $allWidthTargets.css({
        width: '',
        maxWidth: '',
        flex: '',
        marginLeft: ''
      });
    };

    var unsizeHeaders = function() {
      unsizeTargetRows();

      $allCols.css({
        width: '',
        maxWidth: '',
        flex: ''
      });
    };

    var sizeWidthTargets = function() {
      if ( app.breakpointIsMobile() ) return;

      $allWidthTargetRows.each(function() {
        var $targetRow = $(this);
        var targets = $targetRow.find('[data-column-width-target]');

        targets.each(function() {
          var $target = $(this);
          var targetColIds = JSON.parse($target.attr('data-column-width-target'));
          var width = 0;
          var index = 0;
          var offsetLeft = 0;

          targetColIds.forEach(function(id) {
            var $thisTarget = $targetRow.find('[data-column-header="' + id + '"]');
            var thisTargetWidth = $thisTarget.outerWidth(true);

            if ( index == 0 ) {
              offsetLeft = $thisTarget.position().left - 15;
            }

            width += thisTargetWidth;
            index += 1;
          });

          $target.css({
            width: width,
            maxWidth: width,
            flex: 'auto',
            marginLeft: offsetLeft
          });
        });
      });
    };

    var sizeHeaders = function() {
      unsizeHeaders();

      if ( app.breakpointIsMobile() ) return;

      $scopes.each(function() {
        var $scope = $(this);
        var $rows = $scope.find('[data-column-header-size-match-row]');
        var $main = $rows.filter('[data-column-header-size-match-row="main"]');
        var $secondary = $rows.filter('[data-column-header-size-match-row="secondary"]');
        var $mainCols = $main.find('[data-column-header]');

        $mainCols.each(function(index) {
          var $mainCol = $(this);
          var width = $mainCol.outerWidth(true);

          $secondary.each(function() {
            var $secondaryCols = $(this).find('[data-column-header]');
            var $secondaryCol = $secondaryCols.eq(index);

            $secondaryCol.css({
              width: width,
              maxWidth: width,
              flex: 'auto'
            });
          });
        });
      });

      sizeWidthTargets();
    };

    app.$window.on('resize.columnHeaderSizeMatch', throttle(sizeHeaders, 200));

    sizeHeaders();

    var timers = [];
    // Resize again after page load to account for font display size differences
    timers.push(window.setTimeout(function() {
      sizeHeaders();
      timers.push(window.setTimeout(function() {
        sizeHeaders();
      }, 300));
    }, 100));

    app.teardown.push(() => {
      unsizeHeaders();
      timers.forEach(function(timer) {
        if ( timer ) window.clearTimeout(timer);
      });
    });
  });
})();
