import app from "../app";
import Tablesort from 'tablesort'

export class TableSort {
  constructor() {
    const tables = document.querySelectorAll('[data-table-sort]');

    if ( !tables.length ) return;

    tables.forEach((table) => {
      new Tablesort(table);

      var $table = $(table);

      table.addEventListener('afterSort', function() {
        // Disable scroll reveal once a user has sorted a table to avoid issues where it looks like
        // there are empty rows in the table.
        $table.find('.scroll-reveal').removeClass('scroll-reveal');
      });
    });
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  app.pageLoad.push(() => {
    new TableSort();
  });
})();
