/* global $ */
// UNCOMMENT FOR DEBOUNCE OR THROTTLE SUPPORT. NOTE: REQUIRES LODASH TO BE INSTALLED
// import debounce from "lodash/debounce";
// import throttle from "lodash/throttle";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

class App {
  constructor() {
    this.pageLoad = [];
    // this.pageResize = [];
    // this.pageScroll = [];
    // this.pageThrottledScroll = [];
    // this.pageDebouncedResize = [];
    // this.breakpointChange = [];
    this.teardown = [];

    this.runFunctions = (array) => {
      for (var i = 0; i < array.length; i++) {
        array[i]();
      }
    };

    // NOTE: MAKE THIS MATCH YOUR SCSS BREAKPOINTS
    this.breakpoints = {
      xl: 1600,
      lg: 1100,
      md: 768,
      sm: 576,
      xs: 0
    };

    // Initial state
    this.$window = $(window);
    this.$document = $(document);
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.breakpoint = this.getBreakpoint();
    this.scrollTop = window.scrollY;

    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY;
    });

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.breakpoint = this.getBreakpoint();
    });

    // https://turbo.hotwired.dev/handbook/building#observing-navigation-events
    document.addEventListener('turbo:load', () => {
      // console.log('turbo:load');
      this.$html = $('html');
      this.$body = $('body');
      this.$header = $('#header');
      this.$cursor = $('#cursor');
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.breakpoint = this.getBreakpoint();
      this.scrollTop = window.scrollY;

      this.runFunctions(this.pageLoad);
    });

    const pageLoadEvents = [
      'economy:magic_module_updated',
      'economy:magic_module_order_updated',
      'economy:init:fields'
    ];

    this.$window.on(pageLoadEvents.join(' '), () => {
      this.runFunctions(this.pageLoad);
    });

    // https://turbo.hotwired.dev/handbook/building#preparing-the-page-to-be-cached
    document.addEventListener('turbo:before-cache', () => {
      // console.log('turbo:before-cache');
      this.runFunctions(this.teardown);
    });
  }

  reflow() {
    // Run any resize function necessary that may depend on the document body changing in height
    this.$document.trigger('app:reflow');
  }

  // NOTE: do not call this method directly. Opt instead to use `this.breakpoint`
  getBreakpoint() {
    const [breakpoint] = Object.entries(this.breakpoints).find(
      ([_, minViewportSize]) => window.innerWidth >= minViewportSize
    );
    return breakpoint;
  }

  breakpointIsMobile() {
    return this.breakpoint === "xs" || this.breakpoint === "sm";
  }

  isHomePage() {
    return this.$body.hasClass('controller--home_pages');
  }

  isEditing() {
    return (this.$html.hasClass('edit') || this.$html.hasClass('record'));
  }

  scrollTo(targetOrPosition, offset, callback) {
    let smoothScrollOffset = (offset || offset == 0) ? offset : (this.$header.outerHeight(true) + 15);
    let duration = 1;
    let scrollTop;

    if ( isNaN(targetOrPosition) ) {
      if ( !targetOrPosition.length ) {
        console.warn('Can\'t find target to scroll to.', targetOrPosition);
        return;
      } else {
        scrollTop = targetOrPosition.offset().top - smoothScrollOffset;
      }
    } else {
      scrollTop = targetOrPosition;
    }

    gsap.to(window, {
      ease: 'power2.inOut',
      duration:duration,
      scrollTo: scrollTop,
      onComplete: () => {
        if ( callback ) callback();
      }
    });
  }

  inViewport = function(el) {
    el = (el instanceof jQuery) ? el.get(0) : el;
    var rect = el.getBoundingClientRect();
    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    var vertInView = (rect.top <= this.windowHeight) && ((rect.top + rect.height) >= 0);
    var horInView = (rect.left <= this.windowWidth) && ((rect.left + rect.width) >= 0);
    return (vertInView && horInView);
  }


}
export default new App();
