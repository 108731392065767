// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../stylesheets/application.scss";
import app from "../javascript/app";
import "../javascript";

import "../images/favicons/favicon.ico";
import "../images/favicons/favicon-16x16.png";
import "../images/favicons/favicon-32x32.png";
import "../images/favicons/apple-touch-icon.png";

window.App = app;

const images = require.context('../images', true)

// Economy edit javascript

app.$document.on('change', '[data-toggle-panel-type]', (e) => {
  const $togglePanel = $(e.target);
  const $fieldSet = $togglePanel.closest('.nested-fields');
  const $panels = $fieldSet.find('[data-toggle-panel]');
  const panelType = $togglePanel.val();
  const $panel = $fieldSet.find('[data-toggle-panel="' + panelType + '"]');

  if ( $panel.length ) {
    $panels.not($panel).hide().find(':input').prop('disabled', true);
    $panel.show().find(':input').prop('disabled', false);
  } else {
    console.warn('Could not find matching toggle-panel for panel type', panelType);
  }
});

app.$document.on('cocoon:after-insert', (e, $item) => {
  const $mm = $item.closest('.image-grid-module');
  const $togglePanels = $mm.find('[data-toggle-panel-type]');

  if ( $togglePanels.length ) {
    $togglePanels.each((index, togglePanel) => {
      const $togglePanel = $(togglePanel);

      $togglePanel.trigger('change');
    });
  }
});

app.$document.on('economy:init:fields', (e) => {
  const $mm = $(e.target);
  const $togglePanels = $mm.find('[data-toggle-panel-type]');

  if ( $togglePanels.length ) {
    $togglePanels.each((index, togglePanel) => {
      const $togglePanel = $(togglePanel);

      $togglePanel.trigger('change');
    });
  }
});
