import app from "../app";
import { gsap } from "gsap";

// <div class="accordion">
//   <div class="accordion__expander">
//     Click to Expand
//   </div>
//   <div class="accordion__content">
//     <div class="accordion__content__inner">
//       Content goes here
//     </div>
//   </div>
// </div>

export class Accordions {
  constructor() {
    var duration = 1;

    var closeAccordion = function($accordion, $expander) {
      // Close accordion

      // if ( $accordion.hasClass('accordion--transitioning') ) return;
      if ( !$accordion.hasClass('accordion--active') ) return;

      $accordion.addClass('accordion--transitioning');

      var $content = $accordion.find('.accordion__content').first();
      var $openIndicatorContent = $accordion.find('.accordion__expander__open-content').first();
      var $closeIndicatorContent = $accordion.find('.accordion__expander__close-content').first();
      var toggleBodyClass = $accordion.attr('data-toggle-body-class');
      var contentHeight;

      $content.css({ display: 'block' });
      $accordion.add($expander).removeClass('accordion--active');
      $openIndicatorContent.show();
      $closeIndicatorContent.hide();
      contentHeight = $content.outerHeight(true);

      if ( $accordion.attr('data-duration') ) {
        duration = parseInt($accordion.attr('data-duration'));
      }

      $content.css({ height: contentHeight, display: 'block', opacity: 1 });

      if ( toggleBodyClass ) app.$body.removeClass(toggleBodyClass);

      gsap.killTweensOf($content[0]);
      gsap.to($content[0], {
        duration: duration,
        ease: 'power4.out',
        opacity: 0,
        height: 0,
        onStart: function() {
          app.reflow();
        },
        onComplete: function() {
          $content.hide();
          $accordion.removeClass('accordion--transitioning');
          app.reflow();
        }
      });
    };

    var openAccordion = function($accordion, $expander) {
      // Open accordion

      // if ( $accordion.hasClass('accordion--transitioning') ) return;
      if ( $accordion.hasClass('accordion--active') ) return;

      $accordion.addClass('accordion--transitioning');

      var $content = $accordion.find('.accordion__content').first();
      var $openIndicatorContent = $accordion.find('.accordion__expander__open-content').first();
      var $closeIndicatorContent = $accordion.find('.accordion__expander__close-content').first();
      var toggleBodyClass = $accordion.attr('data-toggle-body-class');
      var contentHeight;

      $accordion.add($expander).addClass('accordion--active');
      $openIndicatorContent.hide();
      $closeIndicatorContent.show();
      $content.css({ opacity: 0, height: '' }).show();
      contentHeight = $content.outerHeight(true);
      $content.css({ height: 0 });

      if ( $accordion.attr('data-duration') ) {
        duration = parseInt($accordion.attr('data-duration'));
      }

      if ( toggleBodyClass ) app.$body.addClass(toggleBodyClass);

      gsap.killTweensOf($content[0]);
      gsap.to($content[0], {
        duration: duration,
        ease: 'power4.out',
        opacity: 1,
        height: contentHeight,
        onStart: function() {
          app.reflow();
        },
        onComplete: function() {
          $content.css({ height: '' });
          $accordion.removeClass('accordion--transitioning');
          app.reflow();
        }
      });
    };

    var toggle = function($accordion, $expander) {
      // if ( $accordion.hasClass('accordion--transitioning') ) return;

      if ( $accordion.hasClass('accordion--active') ) {
        closeAccordion($accordion, $expander);
      } else {
        openAccordion($accordion, $expander);
      }
    };

    app.$document.on('click.accordions', '.accordion__expander', function(e) {
      var $target = $(e.target);

      if ( $target.hasClass('accordion__expander--prevent-default') ) {
        e.preventDefault();
      } else if ( $target.is('a') ) {
        return;
      }

      var $expander = $(this);
      var targetSelector = $expander.attr('data-target');
      var $accordion;

      if ( targetSelector ) {
        $accordion = $(targetSelector);
      } else {
        $accordion = $expander.closest('.accordion');
      }

      toggle($accordion, $expander);
    });

    app.$document.on('keyup.accordions', '.accordion__expander', function(e) {
      // Enter key
      if ( e.which == 13 ) {
        if ( $(e.target).is('a') ) return;

        var $expander = $(this);
        var $accordion = $(this).closest('.accordion');

        toggle($accordion, $expander);
      }
    });

    // app.teardown.push(() => {
    //   app.$document.off('click.accordions keyup.accordions');
    // });
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    new Accordions();
  });
})();
