import app from "../app";

export default (() => {
  app.pageLoad.push(function() {
    if ( !app.$body.hasClass('controller--home_pages') ) return;

    const $wrapper = $('#header__responsive-mobile-logo-col');
    const largeSize = $wrapper.height();
    const smallSize = 40;
    const fixPosition = 250;

    const homeScrollHandler = () => {
      if ( !app.breakpointIsMobile() ) {
        $wrapper.css({ height: '' });
        return;
      }

      if ( app.scrollTop < 0 ) {
        $wrapper.css({ height: largeSize });
      } else if ( app.scrollTop <= fixPosition ) {
        const size = (1 - (app.scrollTop / fixPosition)) * smallSize + smallSize;

        $wrapper.css({ height: size });
      } else {
        $wrapper.css({ height: smallSize });
      }
    };

    window.addEventListener('scroll', homeScrollHandler);

    homeScrollHandler();

    app.teardown.push(() => {
      window.removeEventListener('scroll', homeScrollHandler);
      $wrapper.css({ height: '' });
    });
  });
})();
