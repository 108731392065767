import app from "../app";

export default (() => {
  window.addEventListener('DOMContentLoaded', () => {
    app.$window.on('scroll', () => {
      // if ( app.scrollTop > 0 ) {
      //   app.$body.addClass('has-scrolled');
      // } else {
      //   app.$body.removeClass('has-scrolled');
      // }

      if ( app.scrollTop > app.windowHeight / 2 ) {
        app.$body.addClass('has-scrolled-50vh');
      } else {
        app.$body.removeClass('has-scrolled-50vh');
      }
    });
  });
})();
