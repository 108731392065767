import app from "../app";

export default (() => {
  app.pageLoad.push(function() {
    const $elements = $('[data-observe-intersection]').filter(':visible');

    if ( !$elements.length ) return;

    const $elementsWithMiddleTrigger = $elements.filter('[data-root-margin="middle"]');
    const $elementsWithBottomTrigger = $elements.not($elementsWithMiddleTrigger);

    const onIntersectionObserved = (entries) => {
      entries.forEach(function(entry) {
        var $target = $(entry.target);

        if ( entry.isIntersecting ) {
          $target.addClass('is-intersecting has-intersected');
        } else {
          $target.removeClass('is-intersecting');
        }
      });
    }

    // https://dev.to/joostkiens/creating-practical-instagram-like-galleries-and-horizontal-lists-with-css-scroll-snapping-580e
    // create an observer with the list as intersection root
    var observer = new IntersectionObserver(onIntersectionObserved, {
      root: null,
      rootMargin: '-56px 0px 0px 0px', // header height top offset
      threshold: 0.5 // set offset 0.1 means trigger if atleast 10% of element in viewport
    });

    var middleObserver = new IntersectionObserver(onIntersectionObserved, {
      root: null,
      rootMargin: '-50% 0% -50% 0%', // header height top offset
      threshold: 0 // set offset 0.1 means trigger if atleast 10% of element in viewport
    });

    // observe each element
    $elementsWithBottomTrigger.each(function(index, element) {
      observer.observe(element);
    });

    $elementsWithMiddleTrigger.each(function(index, element) {
      middleObserver.observe(element);
    });

    app.teardown.push(() => {
      observer.disconnect();
      middleObserver.disconnect();
      $elements.removeClass('is-intersecting has-intersected');
    });
  });
})();
