import app from "../app";

export class ExternalLinks {
  constructor() {
    document.querySelectorAll('a').forEach((anchor) => {
      if ( this.isExternalLink(anchor) ) {
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('data-cursorable', 'external-link');
      }
    });
  }

  isExternalLink(element) {
    const documentHost = document.location.href.split('/')[2];
    const href = element.getAttribute('href');
    const linkHost = href ? href.split('/')[2] : null;
    return linkHost != documentHost && !this.internalLinkMatches(href).includes(true) && !this.isSimpleRoute(href);
  };

  internalLinkMatches(href) {
    const internalLinkExceptions = ['mailto:', 'tel:', 'sms:', 'javascript:'];
    return internalLinkExceptions.map((ex) => {
      if ( href ) {
        return href.indexOf(ex) != -1;
      } else {
        return true;
      }
    });
  };

  isSimpleRoute(href) {
    return ["/","#"].includes(href[0]);
  };
}

export default (() => {
  app.pageLoad.push( () => {
    new ExternalLinks();
  });
})();
