import app from "../app";
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import * as viewport from "../utilities/viewport";

export class Video {
  constructor() {
    this.$iframes = $('.autoplay-vimeo-video iframe');

    if ( !this.$iframes.length ) return;

    this.initialize();

    document.addEventListener('turbo:before-cache', () => {
      this.turnOffEventListeners();
    }, { once: true });
  }

  turnOffEventListeners() {
    this.$iframes.each((index, iframe) => {
      let $iframe = $(iframe);
      let player = $iframe.data('player');
      if ( !player ) return;
      player.destroy();
      player = null;
    });
    this.$iframes = null;
    app.$window.off('resize.autoplayVimeoVideos scroll.autoplayVimeoVideos');
    app.$document.off('click.autoplayVimeoVideos', '.autoplay-vimeo-video');
  }

  initialize() {
    this.$iframes.each(function() {
      let iframe = this;
      let $iframe = $(iframe);
      let player = new Vimeo.Player(iframe);

      $iframe.data('player', player);
    });

    let checkForVisibility = () => {
      if ( !this.$iframes || !this.$iframes.length ) return;

      this.$iframes.each((index, element) => {
        let $iframe = $(element);
        let player = $iframe.data('player');
        let isVisible = false;

        if ( viewport.isVisible(element) ) {
          if ( $iframe.data('has-been-paused-by-user') ) return;

          isVisible = true;
        }

        if ( isVisible && !$iframe.is(':visible') ) {
          isVisible = false;
        }

        if ( isVisible ) {
          player.play().then(function() {
            // Player is playing
          }).catch(function(error) {
            // Play was prevented
            console.warn('Error playing video', error);
          });
        } else {
          player.pause().then(function() {
            // Player is paused
          }).catch(function(error) {
            // Play was prevented
            console.warn('Error pausing video', error);
          });
        }
      });
    };

    app.$window.on('resize.autoplayVimeoVideos', debounce(checkForVisibility, 500));
    app.$window.on('scroll.autoplayVimeoVideos', throttle(checkForVisibility, 500));
    checkForVisibility();
  }
}

// Export an init function that looks for and instantiates the module on pageload
export default (() => {
  app.pageLoad.push( () => {
    new Video();

    app.$document.on('click.autoplayVimeoVideos', '.autoplay-vimeo-video', function() {
      let $element = $(this);
      let $iframe = $element.find('iframe');
      let player = $iframe.data('player');

      player.getPaused().then(function(paused) {
        if ( paused ) {
          player.play().then(function() {
            // Player is playing
            $iframe.data('has-been-paused-by-user', false);
          }).catch(function(error) {
            // Play was prevented
            console.warn('Error playing video', error);
          });
        } else {
          player.pause().then(function() {
            // Player is paused
            $iframe.data('has-been-paused-by-user', true);
          }).catch(function(error) {
            // Play was prevented
            console.warn('Error pausing video', error);
          });
        }
      });
    });
  });
})();
